.HeaderSliderWraper-container {}

.unauthorized-container {
    padding: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.HeaderSliderWraper-container img,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    /* user-select: none !important; */
}