/* Container padding for responsive design */
.main-quick {
    padding: 2.5vh 2vh;
    /* background-color: rgba(182, 239, 73, 0.2); */
    border-radius: 10px;

}

.home-quick-donate {
    padding: 3vh 0vh;
    background-image: url("../../../public/images/creatives/home-quick-donate-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.home-quick-donate .color-white
{
    color: white;
}
.home-quick-donate .color-green
{
    color: #A5CD37;
}

/* Heading styles */
.main-quick .head-h1 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    font-family: "Inter", sans-serif;
}

.home-quick-donate .input-donate-container
{

}

.home-quick-donate .connect-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 700;
    font-size: 1.3rem;
}

/* Centering currency text next to input */
.main-quick .currency {
    font-weight: bold;
    font-size: 1.2rem;
    padding-top: 5px;
}

/* Prevent text wrapping on smaller buttons */
.main-quick .btn-outline-secondary {
    white-space: nowrap;
    background-color: #A5CD37;
    color: white;
    border: none;
    font-weight: 700;
    font-size: 1.3rem;
    transition: background-color 0.3s, color 0.3s;
 
    /* Smooth transition */
}



/* Hover styling */
.main-quick .btn-outline-secondary:hover {
    background-color: #8db02c;
    /* Change to your desired hover background color */
    color: white;
    /* Change to your desired hover text color */
    border-color: #A5CD37;
    /* Change border color on hover, if applicable */
}



/* Input for entering donation amount */
.main-quick input.form-control {
    width: 100px;
    text-align: center;
    border-radius: 26px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: auto;
}

/* Ensure the modal is responsive */
.main-quick .modal-body {
    padding: 20px;
}

/* Center align button in modal */
.main-quick .bottom-button {
    display: flex;
    justify-content: center;
}





/* Responsive adjustments for form fields */
@media (max-width: 576px) {
    .main-quick .connect-button {
        width: 100%;
    }

    .main-quick .modal-body {
        padding: 10px;
    }
}


/* Adjust padding for quick-donate on small screens */
@media (max-width: 576px) {
    .main-quick {
        padding: 5vh 1vh;
    }

    /* Font size adjustments for smaller screens */
    .main-quick .head-h1 {
        font-size: 30px;
    }

    .main-quick input.form-control {
        width: 80px;
    }

    /* Ensuring buttons take full width on very small screens */
    .main-quick .btn {
        width: 100%;
    }

    .home-quick-donate .connect-button {
        text-align: center;
    }
}