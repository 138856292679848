/* CardBox.css */

.services-card-box {
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #fff;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-card-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-card-box img {
  width: 100%;
  height: auto;
}

.services-card-box .card-body {
  padding: 3vh 3vh;
  height: 270px;
}

.services-card-box .card-body .head-h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #1F2F54;
  font-weight: 700;

}

.services-card-box .card-content {
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #A5CD37 #f1f1f1;
  /* For Firefox */
}

/* .services-card-box .card-content::-webkit-scrollbar {
  width: 7px;
  
}

.services-card-box .card-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  
}

.services-card-box .card-content::-webkit-scrollbar-thumb {
  background-color: #f39c12;
  
  border-radius: 10px;
  
} */

.services-card-box .read-more-anchor {
  display: inline-block;
  margin-top: 10px;
  color: #A5CD37;
  font-weight: bold;
}

.services-card-box .btn-icon {
  width: 15px;
  margin-left: 5px;
  vertical-align: middle;
}

.services-card-box .button-secondary {
  margin-top: 8px;
}