.events-container {
    padding: 6vh 0vh;
    border-radius: 8px;
    margin: 0 auto;
    width: 100%;
    background: transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 70%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat padding-box;
    /* max-width: 1300px;  */
}

.events-container .under-container {
    padding: 5vh 3vh;
    background-color: #F9F9F9;
}

.events-container .top-text-btn {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0vh 0vh 1vh 0vh;
}

.events-container .date-button {
    background-color: #A5CD37;
    color: white;
    border: none;
    padding: 7px 38px;
    border-radius: 26px;
    font-size: 14px;
    cursor: pointer;
}

.events-container .primary-button {
    margin-top: 18px;
}

h2 {
    font-weight: bold;
}

.btn-link {
    text-decoration: none;
    color: #717C93;
    padding-left: 0px;
}

.events-container .img-responsive {
    max-width: 100%;
    border-radius: 12px;
}

.arrow-img {
    margin-left: 7px;
}

.events-container .h2_main {
    color: #1972B7;
    margin-bottom: 0px;
}

.events-container .h1_main {
    font-size: 45px;
    letter-spacing: 0px;
    color: #1F2F54;
    margin-bottom: 12px;
}

.events-container .connect-button {
    margin-top: 18px;
}

@media (max-width: 768px) {
    .events-container .under-container {
        text-align: center;
    }

    .events-container .top-text-btn {
        flex-direction: column;
        gap: 16px;
        align-items: center;
        padding: 0vh 0vh 1vh 0vh;
    }

    .events-container .h1_main {
        font-size: 22px;
        margin-top: 12px;
    }

    .events-container .h2_main {
        font-size: 16px;
    }
}