.outside {
    padding: 4vh 0vh;
}

.video-grid .text-muted {
    color: #1972B7 !important;
}

.custom-card {
    padding: 0px;
    border-radius: 8px;
}

.video-grid .card-title {
    color: #1F2F54;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    padding: 4px 6px;
}

.video-grid .href {
    color: #A5CD37;
    margin-top: 5px;
}

.video-grid .card-body {
    padding: 22px 0px;
}

.video-grid .card-thumbnail-image {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.video-thumbnail {
    position: relative;
    cursor: pointer;
}

.video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Play/Pause Button Overlay */
.play-pause-button-videos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.466);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.play-pause-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.video-modal .modal-header {
    background-color: #f8f9fa;
    /* Light background for header */
}

.video-modal .modal-body {
    padding: 0;
    /* Remove padding for a better fit */
}

.video-iframe {
    width: 100%;
    /* Make the iframe responsive */
    height: 400px;
    /* Set a fixed height */
}

.modal-footer {
    justify-content: center;
    /* Center the footer buttons */
}

/* Additional styling for the play button */
.play-pause-button-videos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    /* Increase size of play icon */
}

@media (max-width: 768px) {
    .video-grid .card-title {
        font-size: 16px;
    }
}