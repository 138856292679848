.event-grid .text-muted {
    color: #1972B7 !important;
}

.event-grid .card-title {
    color: #1F2F54;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
}

/* .outside {
    background: transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 80%, #A5CD375C 100%, #bbff00 0%) 0% 0% no-repeat padding-box;
} */

.event-grid .href {
    color: #A5CD37;
    margin-top: 5px;
}

.event-grid .card-body {
    padding: 22px 0px;
}

.event-grid .card-img-top {
    border-radius: 10px;
}


@media(max-width:768px) {
    .event-grid .card-body {
        /* text-align: center; */
    }
}