.main-section-container {
    background: transparent linear-gradient(11deg, #FFFFFF00 0%, #FFFFFE00 0%, #A5CD375C 100%, #A5CD37 100%) 0% 0% no-repeat padding-box;
    padding: 20vh 0vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.main-section-container .img-container {
    padding: 0vh 0vh;
    display: flex;
    justify-content: center;
}

.main-section-container .events-inner-section {
    margin: 0 auto;
}

.main-section-container .head-h1 {
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    color: #1F2F54;
}

.main-section-container .para_main {
    text-align: center;
    margin-bottom: 20px;
}

.main-section-container .events-inner-section .connect-button {
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 auto 20px;
}

.main-section-container .img-container {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Media Queries */

/* For small devices (mobiles, up to 600px) */
@media (max-width: 768px) {
    .main-section-container {
        padding: 10vh 0vh;
    }

    .main-section-container .head-h1 {
        font-size: 28px;
    }

    .main-section-container .para_main {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .main-section-container .events-inner-section .connect-button {
        font-size: 14px;
    }
}

/* For medium devices (tablets, 600px to 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
    .main-section-container {
        padding: 15vh 0vh;
    }

    .main-section-container .head-h1 {
        font-size: 36px;
    }

    .main-section-container .para_main {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .main-section-container .events-inner-section .connect-button {
        padding: 12px 30px;
        font-size: 16px;
    }
}

/* For large devices (desktops, 1025px and above) */
/* @media (min-width: 1025px) {
    .main-section-container {
        padding: 20vh 0vh;
    }

    .main-section-container .head-h1 {
        font-size: 45px;
    }

    .main-section-container .para_main {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .main-section-container .events-inner-section .connect-button {
        padding: 15px 40px;
        font-size: 18px;
    }
} */