.signup-popup-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vh 10vh;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.login-popup-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vh 10vh;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.login-popup-section {
  background-color: white;
}

.signup-popup-section {
  background: transparent linear-gradient(223deg, #1972B7 0%, #154E7A 100%) 0% 0% no-repeat padding-box;
  color: white;
}

.login-popup-box,
.signup-popup-box {

  width: 100%;
  padding: 20px;
  text-align: center;

}

.login-popup-box h2,
.signup-popup-box h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.signup-popup-box h2 {
  color: white;
}

.social-login {
  margin: 20px 0;
}

.social-login .btn {
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-login .btn i {
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  height: 45px;
  border-radius: 5px;
  font-size: 12px;
  color: #717C93;
}

.forgot-password {
  display: block;
  margin-bottom: 15px;
  color: #A7A7A7;
  font-size: 13px;
}

.login-section .connect-button {
  font-size: 13px;
}

.btn-block {
  width: 100%;
  background-color: #e91829;
}

.signup-popup-box p {
  margin: 20px 0;
}

.btn-outline-light {
  border-color: white;
  color: white;
  padding: 6px 45px;
  font-size: 12px;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #A7A7A7;
  font-size: 12px;

}

.form-login .form-group input {
  border-radius: 50px;
  padding-left: 18px;
}

.create-accout-section .img-modal {
  top: 0;
  right: 0;
  position: absolute;
}

.login-popup-section .icon-cross {
  height: 28px;
}

.login-popup-section .connect-button {
  color: white;
}

@media (max-width: 768px) {
  .login-popup-section {
    padding: 4vh 3vw;
    border-radius: 10px;
    height: 100vh;
  }

  .login-popup-box {
    padding: 4vh 4vh;
  }

  .btn-outline-light {
    padding: 6px 30px;
  }

  .login-popup-section {
    padding: 8vh 2vh;
  }

  .login-popup-section .icon-cross {
    margin-top: -960px;
  }

  .login-popup-section .img-modal {
    width: 100%;

  }
}

/* @media (max-width: 576px) {
  .login-popup-section {
    padding: 11vh 3vw; 
      border-radius: 10px;
      height: 100vh;
  }

  .login-popup-box {
      padding: 1vh 1vh; 
    }
  
  .login-popup-box h2
  {
    font-size: 1.5rem; 
  }

  
  .btn-outline-light {
    padding: 6px 20px; 

  }

  .login-popup-box .forgot-password{
    color: #717C93;
  }

  .login-popup-section .icon-cross{
    margin-top: -900px;
  }
} */