.main-donation-container {
  padding: 4vh 0vh;
}

.Donation-form-container {
  margin: 20px;
  border: solid 1px #9b9b9b57;
  padding: 40px 40px;
}

.Donation-form-container .under-form-container {
  display: flex;
  gap: 12px;
}

/* .Donation-form-container .account-details-box {
  border: 1px solid gray;
  padding: 20px 30px;
} */

.Donation-form-container .form-group {
  margin-bottom: 15px;
  width: 100%;
}

.Donation-form-container .form-row {
  display: flex;
  width: 100%;
}

.Donation-form-container .form-rs-price {
  font-size: 40px;
  color: #1F2F54;
  padding: 12px 18px;
}

.Donation-form-container .connect-button {
  background-color: #1972B7;
  /* margin-top: 12px; */
  border-radius: 4px;
  padding: 0px 35px;
  font-size: 35px;
}

.Donation-form-container .bottom-button .connect-button {
  width: 100%;
  background-color: #A5CD37;
  font-size: 20px;
  border-radius: 50px;
  padding: 5px 0px;
  margin-top: 15px;
}

.Donation-form-container .form-text {
  color: #1972B7;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 12px;
}

.Donation-form-container .input-group {
  display: flex;
  align-items: center;
}

.Donation-form-container .input-group input {
  flex: 1;
}

.Donation-form-container .input-group button {
  margin-left: 10px;
}

.Donation-form-container .btn-group {
  display: flex;
  align-items: center;
}

.Donation-form-container .btn-group .connect-button {
  margin-right: 0px;
  margin-bottom: 18px;
}

.Donation-form-container .amount-display {
  font-size: 1.5em;
  font-weight: bold;
}

.Donation-form-container .add-name {
  display: flex;
  border-bottom: 1px solid rgba(202, 199, 199, 0.466);
  padding: 20px;
  width: 100%;
}

.Donation-form-container .btn {
  background-color: #1972B7;
  padding: 6px 0px;
}

.Donation-form-container .primary-button {
  border: none;
}

.Donation-form-container .btn-group .head-h2 {
  padding: 20px;
}

.Donation-form-container {}

.Donation-form-container .profile-form-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: rgb(92, 92, 92);
}

.Donation-form-container .profile-form-field:focus {
  outline: none;
}

.Donation-form-container .genral-btn {
  background-color: #EE202D;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  width: 30%;
}

.Donation-form-container .total-text {
  font-weight: 700;
  font-size: 20px;
}

.Donation-form-container .color-red {
  color: #A5CD37;
}

.Donation-form-container .copy-btn {
  border-radius: 50%;
  background-color: rgb(224, 224, 224);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
}

.Donation-form-container .medium-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.Donation-form-container .bank-details-text {
  font-weight: 700;
  font-size: 17px;
}

.Donation-form-container .banking-details-section {
  border: 1px solid grey;
  padding: 28px 20px;
  width: 368px;
  border-radius: 20px;
}

.Donation-form-container .account-row {
  gap: 14px;
}

.Donation-form-container .card-body {
  border-radius: 30px;
}

.Donation-form-container .Account-image-div {
  background-color: rgba(145, 145, 145, 0.534);
  border-radius: 50%;
  padding: 20px 20px;
  margin-right: 25px;
}

.Donation-form-container .bank-logo {
  width: 90px;
}

.Donation-form-container .para_main {
  color: #1972B7;
  font-weight: 600;
  font-size: 11px;
  padding: 0px 3px;
}

.Donation-form-container .detail-text {
  margin: 0;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
  color: #1F2F54;
  margin-top: 8px;
  line-height: 2;
  font-size: 11px;
}

.Donation-form-container .head-h1 {
  font-size: 40px;
}

/* Separate work for Donation Form Popup */

.bank-logo-popup {
  width: 35px;
}

.popup-card {
  padding: 0px;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
}

.card-body-popup {
  height: 450px;
}

.detail-text-popup {
  margin: 0;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
  color: #1F2F54;
  margin-top: 8px;
  line-height: 2;
  font-size: 13px;
}

@media (max-width: 576px) {

  .popup-card {
    margin-bottom: 10px;
    /* Less margin on small screens */
  }

  .detail-text-popup {
    font-size: 12px;
  }

  .card-body-popup {
    height: 382px;
  }
}


@media (max-width: 1600px) {
  .Donation-form-container {
    padding: 50px 40px;
    /* Increased padding for larger screens */
  }

  .Donation-form-container .form-rs-price {
    font-size: 45px;
    /* Larger text size */
  }

  .Donation-form-container .connect-button {
    font-size: 40px;
    padding: 15px 50px;
    /* Adjusted padding for the larger screen */
  }

  .Donation-form-container .banking-details-section {
    border: 1px solid grey;
    padding: 40px 30px;
    border-radius: 20px;
    width: 500px;
    /* Scales better on larger screens */
    margin-bottom: 20px;
  }

  .Donation-form-container .total-text,
  .Donation-form-container .bank-details-text {
    font-size: 22px;
  }

  .Donation-form-container .icon-image,
  .Donation-form-container .bank-logo {
    height: 50px;
  }

  .Donation-form-container .card-body {
    border-radius: 30px;
  }

  .Donation-form-container .Account-image-div {
    background-color: rgba(145, 145, 145, 0.534);
    border-radius: 50%;
    padding: 20px 20px;
    margin-right: 25px;
  }

  .Donation-form-container .bank-logo {
    width: 100%;
    height: 75px;
  }

  .Donation-form-container .head-h1 {
    font-size: 34px;
  }
}


@media (max-width: 768px) {

  .Donation-form-container {
    padding: 30px 20px;
    border-radius: 10px;
  }

  .Donation-form-container .under-form-container {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .Donation-form-container .form-rs-price,
  .Donation-form-container .connect-button {
    font-size: 30px;
    padding: 10px 25px;
  }

  .Donation-form-container .bottom-button .connect-button {
    font-size: 18px;
    padding: 10px 0;
  }

  .Donation-form-container .form-text {
    font-size: 20px;
  }

  .Donation-form-container .amount-display {
    font-size: 1.2em;
  }

  .Donation-form-container .add-name {
    padding: 15px;
  }

  .Donation-form-container .btn-group .connect-button {
    margin-right: 0px;
    padding: 5px 18px;
    align-items: center;
    margin-bottom: 18px;
  }

  .Donation-form-container .btn-group .head-h2 {
    font-size: 18px;
    padding: 10px;
    align-items: center;
  }

  .Donation-form-container .form-rs-price,
  .Donation-form-container .connect-button {
    font-size: 22px;
    padding: 8px 20px;
  }

  .under-form-container .button-container-donation {
    padding: 1vh 0vh;
    align-items: center;
    justify-content: center;
  }

  .under-form-container .donation-form-btn {
    padding: 3px 22px;
  }

  /* .Donation-form-container .banking-details-section {
    width: 100%;
    padding: 20px;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .Donation-form-container .total-text,
  .Donation-form-container .bank-details-text {
    font-size: 16px;
  }

  .Donation-form-container .icon-image,
  .Donation-form-container .bank-logo {
    height: 40px;
  } */

  .Donation-form-container .banking-details-section {
    width: 100%;
    padding: 20px;
    margin-bottom: 15px;
    flex-direction: row-reverse;
  }

  .Donation-form-container .total-text,
  .Donation-form-container .bank-details-text {
    font-size: 16px;
  }

  .Donation-form-container .icon-image,
  .Donation-form-container .bank-logo {
    height: 40px;
  }

  .Donation-form-container .medium-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    text-align: left;
    padding: 0px;
    width: 100%;
    margin: 0;
  }

  .Donation-form-container .account-row {
    gap: 14px;
    flex-direction: row-reverse;
  }

  .Donation-form-container .card-body {
    border-radius: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .Donation-form-container .Account-image-div {
    background-color: rgba(145, 145, 145, 0.534);
    border-radius: 50%;

    margin-right: 0px;
  }

  .Donation-form-container .bank-logo {
    width: 100%;
    height: 75px;
  }

  .Donation-form-container .head-h1 {
    font-size: 30px;
  }
}


@media (max-width: 556px) {

  .Donation-form-container {
    padding: 20px 10px;
  }

  .Donation-form-container .under-form-container {
    flex-direction: column;
    gap: 8px;
  }

  .Donation-form-container .bottom-button .connect-button {
    font-size: 16px;
    padding: 8px 0;
  }

  .Donation-form-container .form-text {
    font-size: 18px;
  }

  .Donation-form-container .amount-display {
    font-size: 1em;
  }

  .Donation-form-container .add-name {
    padding: 10px;
  }

  .Donation-form-container .bank-details-text {
    font-weight: 700;
    font-size: 18px;
  }

  .Donation-form-container .total-text {
    font-weight: 700;
    font-size: 18px;
  }

  .Donation-form-container .banking-details-section {
    width: 100%;
    padding: 6px;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .Donation-form-container .total-text,
  .Donation-form-container .bank-details-text {
    font-size: 14px;
  }

  /* .Donation-form-container .icon-image,
  .Donation-form-container .bank-logo {
    height: 35px;
  } */

  .Donation-form-container .account-row {
    gap: 14px;
    flex-direction: row-reverse;
  }

  /* .Donation-form-container .banking-details-section {
    width: 100%;
    padding: 20px;
    margin-bottom: 15px;
    flex-direction: row-reverse;

  }

  .Donation-form-container .total-text,
  .Donation-form-container .bank-details-text {
    font-size: 14px;
  }

  .Donation-form-container .icon-image,
  .Donation-form-container .bank-logo {
    height: 35px;
  } */
}