.event-slider-container {
    padding: 5vh 0vh;
}

.event-slider-container-container .text-muted {
    color: #1972B7 !important;
}

.event-slider-container .card-title {
    color: #1F2F54;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
}

.event-slider-container .href {
    color: #A5CD37;
    margin-top: 5px;
}

.event-slider-container .card-body {
    padding: 22px 0px;
}

.event-slider-container .card-img-top {
    border-radius: 10px;
}

.event-slider-container-text {
    padding: 1vh 0vh;
}

.event-slider-container .head-h1 {
    color: #1F2F54;
    font-size: 38px;
    margin-bottom: 1rem;
    font-weight: 600;
}




@media(max-width:768px) {
    .event-slider-container .card-body {
        /* text-align: center; */
    }
}