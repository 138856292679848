.video-slider-container {
    padding: 6vh 0vh;
}

.video-slider-container .text-muted {
    color: #1972B7 !important;
}

.video-slider-container .card-title {
    color: #1F2F54;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
}

.video-slider-container .href {
    color: #A5CD37;
    margin-top: 5px;
}

.video-slider-container .card-body {
    padding: 22px 0px;
}

.video-slider-container .card-thumbnail-image {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.video-thumbnail {
    position: relative;
    cursor: pointer;
}

.video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-slider-container .head-h3 {

    color: #1F2F54;
    font-size: 38px;

}

/* Play/Pause Button Overlay */
/* .play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
} */

.video-modal .modal-header {
    background-color: #f8f9fa;
    /* Light background for header */
}

.video-modal .modal-body {
    display: flex;
    /* Use flexbox for centering */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    padding: 0;
    /* Remove padding for a better fit */
}

.video-iframe {
    width: 100%;
    /* Make the iframe responsive */
    height: 400px;
    /* Set a fixed height */
    max-height: 400px;
    /* Restrict max height */
}

.modal-footer {
    justify-content: center;
    /* Center the footer buttons */
}

/* Additional styling for the play button */
.play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    /* Increase size of play icon */
}



@media (max-width: 768px) {
    .video-slider-container .card-title {
        font-size: 16px;
    }
}