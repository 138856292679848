.home-hero {
  position: relative;
  height: auto;
  background: url('../../../public/images/creatives/Home-banner.jpg') no-repeat center center/cover;
}

.homeslider-container {
  position: relative;
  height: auto;
}


.Homehero-content .head-h1 {
  font-weight: 700;
  letter-spacing: 0px;
  color: #1972B7;
  text-align: left;
  font-size: 42px;
}

.Homehero-content .para_main {
  margin-bottom: 14px;
  width: 85%;
}

.Homehero-content .connect-button {
  margin-top: 12px;
}

.Homehero-content .main-head {
  color: #717C93;
  font-size: 19px;
}

.social-media-icons {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.home-hero .swiper-container {
  height: 100%;
  position: relative;
}

.home-hero .swiper-slide {
  position: relative;
  left: 0;
  z-index: 1;
  height: auto;
  display: flex;
  align-items: center;
}

.home-hero .swiper-pagination {
  display: flex;
  gap: 5px;
  justify-content: center;
  transform: translateY(-50%);
}

.home-hero .swiper-pagination .swiper-pagination-bullet {
  background-color: #dededec2;
  opacity: 1;
  width: 11px;
  height: 11px;
}

.home-hero .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #A5CD37;
  opacity: 1;
  width: 13px;
  height: 13px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.social-media-icons img {
  max-width: 100%;
  height: auto;
  transition: transform 0.2s ease-in-out;
  border-radius: 10px;
}

.social-media-icons img:hover {
  transform: translateY(-18px);
}

@media (max-width: 1024px) {
  .Homehero-content .head-h1 {
    font-size: 2em;
  }

  .Homehero-content .para_main {
    width: 100%;
  }

  .social-media-icons {
    right: 30px;
  }
}

@media (max-width: 768px) {
  .home-hero {
    height: auto;
    padding: 20px 8px;
  }

  .homeslider-container {
    height: auto;
    padding: 20px 8px;
  }

  .Homehero-content .head-h1 {
    font-size: 1.5em;
    text-align: center;
  }

  .Homehero-content .para_main {
    width: 100%;
    text-align: center;
  }

  .Homehero-content .connect-button {
    margin: 20px auto;
    display: block;
  }

  .social-media-icons {
    position: static;
    margin: 20px auto;
    transform: none;
    flex-direction: row;

    justify-content: center;
  }

  .social-media-icons img {
    max-width: 30px;
    margin-bottom: 12px;
  }

  .Homehero-content .main-head {
    text-align: center;
    margin-top: 14px;
    font-size: 14px;
  }

  .Homehero-content .connect-button {
    border: none;
    text-decoration: none;
    color: #FFFFFF;
  }

  .home-hero .swiper-pagination {
    flex-direction: unset;
    justify-content: center;
  }

  .home-hero .anchor-homehero {
    text-decoration: none;
  }

  .home-hero .khatam-e-nabuwat {
    width: 150px;
  }
}

@media (max-width: 480px) {
  .Homehero-content .head-h1 {
    font-size: 1.5em;
  }

  .Homehero-content .para_main {
    font-size: 0.9em;
  }

  .social-media-icons img {
    max-width: 25px;
  }
}