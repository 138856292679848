.zakat-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.zakat-title {
  font-size: 2.5em;
  color: #0b72b9;
}

.zakat-description {
  font-size: 1em;
  color: #555;
  margin: 20px 0;
}

.zakat-container .head-h1 {
  font-size: 50px;
}

.zakat-container .span-black {
  color: black;
}

.prices-container {
  border: 1px solid #ddd;
  padding: 4vh 4vh;
  border-radius: 8px;
  text-align: start;
}

.prices-container .prices {
  /* font-size: 14px;
    gap: 4px; */
  /* justify-content: start;
    gap: 50px; */
}

.gold-prices,
.silver-prices {
  margin-bottom: 20px;
}

.prices-container .head-h1 {
  font-size: 30px;
}

.prices-container .para_main {
  font-size: 14px;
}


/*   
  .gold-prices h2, .silver-prices h2 {
    font-size: 1.5em;
    color: #0b72b9;
    margin-bottom: 10px;
  }
  
  .gold-prices ul {
    list-style: none;
    padding: 0;
  }
  
  .gold-prices li, .silver-prices p {
    font-size: 1em;
    color: #555;
    margin: 5px 0;
  } */



.zakat-calculation-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.zakat-calculation-container .zakat-title {
  font-size: 2.5em;
  color: #0b72b9;
}

.input-container-for-zakat {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 20px;
  border-radius: 8px;
  text-align: left;
  margin: 0 auto;
}

.input-container-main {


  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
}

.input-group {
  flex: 1 1 30%;
  margin: 10px;
  min-width: 200px;
}

.input-group label {
  display: block;
  font-size: 13px;
  color: #717c93;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  font-size: 1em;
  color: #000000;
  outline: none;
}

.zakat-result {
  margin: 20px 0;
  font-size: 1.5em;
  color: #0b72b9;
}

.zakat-payable-label {
  font-weight: bold;
  font-size: 24px;
  color: black;
  text-decoration: underline;
}

.zakat-payable-amount {
  font-size: 24px;
  margin-left: 10px;
  color: #1972b7;
  text-decoration: underline;
}

.calculate-button {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding: 8px 42px;
  border: none;
  border-radius: 55px;
  font-weight: 300;
  color: #ffffff;
  background-color: #1972b7;
  margin-left: 28px;
}

.calculate-button:hover {
  background-color: #095a8d;
}

@media (max-width: 992px) {
  .prices-container {
    padding: 10px;
    text-align: center;
  }

  .prices-container .head-h1 {
    font-size: 20px;
  }

  .gold-prices,
  .silver-prices {
    margin-bottom: 10px;
  }

  .prices {
    flex-direction: column;
  }

  .input-container-for-zakat {
    padding: 10px 0px;
  }

  .input-container-main {
    padding: 10px 4px;
  }

  .input-group {
    flex: 1 1 100%;
  }

  .zakat-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    border: 1px solid #a8a8a836;
    background-color: #f9f9f9;
  }

  .zakat-payable-label,
  .zakat-payable-amount {
    font-size: 1.2em;
    margin-left: 0px;
  }

  .zakat-calculation-container {
    padding: 24px 18px;
    font-family: Arial, sans-serif;
    text-align: center;
  }

  .calculate-button {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .zakat-container .head-h1 {
    font-size: 2rem;
  }

  .zakat-title {
    font-size: 2rem;
  }

  .zakat-container .para_main {
    font-size: 0.9rem;
    margin-bottom: 12px;
  }

  .input-group {
    min-width: auto;
  }
}