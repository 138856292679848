
.Static-hero.main-home-hero-container
{
    background-image: url("../../../public/images/creatives/home-hero-bg.png");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    padding-top: 12%;
}

.Static-hero {
    position: relative;
    /* height: 80vh; */
    height: auto;
    /* background: url('../../../public/images/creatives/Home-banner.jpg') no-repeat center center/cover; */
   
}

.static-content .head-h1 {
    font-weight: 700;
    letter-spacing: 0px;
    color: #1972B7;
    text-align: left;
    font-size: 42px;
}

.static-content .para_main {
    margin-bottom: 14px;
    width: 85%;
}

.static-content .connect-button {
    margin-top: 0px;
    background-color: transparent;
    /* Initial background */
    border: 1px solid #424242;
    color: #424242;
    padding: 10px 38px;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    /* Required for pseudo-elements */
    overflow: hidden;
    /* Ensure the gradient does not overflow */
    transition: color 0.4s;
    /* Transition for text color */
}

/* Add a pseudo-element for the gradient effect */
.static-content .connect-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    /* Start off-screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #9fa09f, #c7c7c7);
    /* Your gradient colors */
    transition: left 0.4s;
    /* Transition for moving the gradient */
    z-index: 0;
    /* Behind the button text */
}

/* Button text */
.static-content .connect-button span {
    position: relative;
    /* Ensure text is above the gradient */
    z-index: 1;
    /* Bring text above the gradient */
}

/* Hover effect */
.static-content .connect-button:hover::before {
    left: 0;
    /* Move gradient into view */
}

.static-content .connect-button:hover {
    color: white;
    border: none;
    /* Change text color on hover */
}

.static-content .connect-button:hover img {
    filter: brightness(0) invert(1);
    /* Change icon color */
}


.static-content .main-head {
    color: #717C93;
    font-size: 19px;
}

.social-media-icons {
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.Static-hero .arrow-icon {
    width: 32px;
}




@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.social-media-icons img {
    max-width: 100%;
    height: auto;
    transition: transform 0.2s ease-in-out;
    border-radius: 10px;
}

.social-media-icons img:hover {
    /* animation: rotate 0.6s linear 1; */
    transform: translateY(-18px);
}




@media (max-width: 1280px) {
    .Static-hero {
        position: relative;
        /* height: 100vh; */
        padding: 5vh 0vh 0vh 0vh;
        /* background: url('../../../public/images/creatives/Home-banner.jpg') no-repeat center center/cover; */
    }
    .static-content .head-h1 {
        font-size: 2em;
    }

    .static-content .para_main {
        width: 100%;
    }

    .social-media-icons {
        right: 30px;
    }
}

@media(max-width:1200px)
{
    .Static-hero.main-home-hero-container
    {
        padding-top: 5%;
    }
}


@media (max-width: 1024px) {
    .static-content .head-h1 {
        font-size: 2em;
    }

    .static-content .para_main {
        width: 100%;
    }

    .social-media-icons {
        right: 30px;
    }
}


@media (max-width: 768px) {
    .Static-hero {
        height: auto;
        padding: 20px 8px;
    }

    .static-content .head-h1 {
        font-size: 1.5em;
        text-align: center;
    }

    .static-content .para_main {
        width: 100%;
        text-align: center;
    }

    .static-content .connect-button {
        margin: 20px auto;
        display: block;
    }

    .social-media-icons {
        position: static;
        margin: 20px auto;
        transform: none;
        flex-direction: row;

        justify-content: center;
    }

    .social-media-icons img {
        max-width: 30px;
        margin-bottom: 12px;
    }

    .static-content .main-head {
        text-align: center;
        margin-top: 14px;
        font-size: 14px;
    }

    .static-content .connect-button {
        padding: 6px 20px;
        text-decoration: none;

    }

    .Static-hero .swiper-pagination {
        flex-direction: unset;
        justify-content: center;
    }

    .Static-hero .anchor-homehero {
        text-decoration: none;
    }

    .Static-hero .khatam-e-nabuwat {
        width: 150px;
    }

    .Static-hero .arrow-icon {
        width: 26px;
    }
}


@media (max-width: 480px) {
    .static-content .head-h1 {
        font-size: 1.5em;
    }

    .static-content .para_main {
        font-size: 0.9em;
    }

    .social-media-icons img {
        max-width: 25px;
    }
}