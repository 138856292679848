.HowAskAAlim-container
{
    background-color: #F0FCDB;
}

.HowAskAAlim-container .main-heading
{
    color: black;
    font-size: 2.2rem;
}
