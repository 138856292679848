.AskAMuftiWorking-container
{

}


.AskAMuftiWorking-container .main-heading
{
    color: black;
    font-size: 2.2rem;


}

.AskAMuftiWorking-container .para_main
{
    color:black;
}


.AskAMuftiWorking-container .table-container {
    display: flex;
    justify-content: center;
    border: 1px solid #d4e157; 
  }
  
  .AskAMuftiWorking-container .table-container .table-row {
    display: flex;
    width: 100%;
  }
  
  .AskAMuftiWorking-container .table-container .table-cell {
    flex: 1;
    text-align: center;
    padding: 20px;
    border: 1px solid #d4e157; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .AskAMuftiWorking-container .table-container h3 {
    color: #0a0f2d; 
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .AskAMuftiWorking-container .table-container p {
    color: #b0b0b0;
    font-size: 14px;
    max-width: 80%;
    text-align: center;
  }
  

  @media(max-width:993px)
  {
    .AskAMuftiWorking-container .table-container .table-row  {
       flex-direction: column;
      }
  }