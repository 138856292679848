.blog-inner-container {
    padding: 12vh 0;
  }
  
  .blog-inner-container .content p {
    font-size: 16px;
    line-height: 1.9;
  }
  
  .blog-inner-container .content h2 {
    margin-top: 60px;
    margin-bottom: 35px;
    font-size: 28px;
  }
  
  .blog-inner-container .ads-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 2vh; 
    height: 100%;
  }
  
  .blog-inner-container .ad {
    width: 100%;
    max-width: 100%; 
    height: auto; 
    border-radius: 8px;
  }
  
  .blog-inner-container .centered-img {
    padding: 5vh 0;
    width: 100%;
    height: auto;
  }
  
  .blog-inner-container .right-img {
    width: 100%;
    height: auto; 
  }
  
  @media (max-width: 767.98px) {
    .blog-inner-container {
        padding: 2vh 2vh;
        text-align: center;
      }
    .blog-inner-container .ads-container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .blog-inner-container .ad {
      /* flex: 1 1 calc(50% - 10px);
      max-width: calc(50% - 10px); */

    }
  
    .blog-inner-container .content h2 {
      font-size: 20px
    }
}