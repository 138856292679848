.AskAMuftiForm-container
{

}

.AskAMuftiForm-container .inp-container
{
}

.AskAMuftiForm-container .inp-container input,
.AskAMuftiForm-container .inp-container textarea
{
    width: 100%;
    background-color: #e2e2e2;
    border-radius: 4px;
    border: none;
    resize: none;
    padding:8px;
    padding-left: 12px;
}

.AskAMuftiForm-container .inp-container input:focus,
.AskAMuftiForm-container .inp-container textarea:focus
{
   outline: none;
}


.AskAMuftiForm-container .previous-question-box
{
    box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.32);
    -webkit-box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.32);
    border-radius: 5px;
}

.AskAMuftiForm-container .previous-question-box .message-container
{
    
}

.AskAMuftiForm-container .previous-question-box .message-container .message-box
{
    background-color: #F2F2F2;
    border-radius: 5px;

    max-height: 150px;
    overflow-y: auto;
}