.data-grid {
  width: 100%;
  margin: 20px 0;
  overflow-x: auto;
  /* user-select: none; */
}

.data-grid .pagination-btns {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
}

.data-grid .pagination-btns .page-size {
  display: flex;
  align-items: center;
  margin: 10px 0;
  border: 1px solid #d7d7d7 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.data-grid .pagination-btns .page-size label span {
  margin-right: 10px;
}

.data-grid .pagination-btns .page-size label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 0px !important;
}

.data-grid .pagination-btns .page-size select {
  padding: 0px !important;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  border: none !important;
  transition: border-color 0.3s ease;
}

.data-grid .pagination-btns .page-size select option {
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.data-grid .pagination-btns .page-size select:focus {
  border-color: #d7d7d7;
}

.data-grid .grid-action-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
}

.data-grid .grid-action-buttons button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 600;
}

.data-grid .parent_checkbox {
  margin-right: 10px;
}

.data-grid .parent_checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: -4px 5px;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

.data-grid .parent_checkbox input[type="checkbox"]:checked {
  background-color: #ccc;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th,
td {
  padding: 10px;
  text-align: left;
  border: 1px solid #d7d7d7;
  border-left: none;
  border-right: none;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr.selected {
  background-color: #e4edf4;
}

.pagination {
  margin-top: 10px;
  text-align: center;
}

.pagination button {
  padding: 5px 15px;
  margin: 0 2px;
  border: none;
  background-color: #b6b6b6;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.pagination button.active {
  background-color: #4d87d7;
}

.pagination button:hover {
  background-color: #4d87d7;
}

.data-grid .refresh-grid-icon {
  border: none;
  background: transparent;
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.data-grid .search-btn {
  padding: 5px 10px;
  margin: 0 2px;
  border: none;
  background-color: #f7f7f7 !important;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.data-grid .search-btn:hover {
  background-color: #4d87d7 !important;
  color: white;
}

.data-grid .grid-main-table {
  height: 500px;
  width: 100%;
  overflow: auto;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
}

.data-grid thead {
  background-color: #f7f7f7;
}

.data-grid th {
  padding-left: 20px;
  border-left: 2px solid rgb(222, 222, 222);
}

.data-grid th:first-child {
  border-left: 0px solid grey;
}

.data-grid .search-input {
  width: 250px;
}


.data-grid .search-input:focus {
  outline: none;
}


@media(max-width:"992px") {

  th,
  td {
    width: 200px;
  }

}