.refund-content {
    padding: 2vh 2vh;
    line-height: 1.6;
    font-family: Arial, sans-serif;
    color: #333;
    /* max-width: 1200px; */
    margin: 0 auto;
}

.refund-content h2,
.refund-content h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.8em;
}

.refund-content p {
    margin-bottom: 15px;
    font-size: 1em;
}

.refund-content ul {
    margin-left: 20px;
    list-style-type: disc;
}

.refund-content ul li {
    margin-bottom: 10px;
    font-size: 1em;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .refund-content {
        padding: 10px;
    }

    .refund-content h2,
    .refund-content h3 {
        font-size: 1.5em;
    }

    .refund-content p,
    .refund-content ul li {
        font-size: 0.9em;
    }
}

/* Medium devices (tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .refund-content {
        padding: 15px;
    }

    .refund-content h2,
    .refund-content h3 {
        font-size: 1.6em;
    }

    .refund-content p,
    .refund-content ul li {
        font-size: 0.95em;
    }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .refund-content {
        padding: 20px;
    }

    .refund-content h2,
    .refund-content h3 {
        font-size: 1.8em;
    }

    .refund-content p,
    .refund-content ul li {
        font-size: 1em;
    }
}