.darul-contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fffefe;
    padding: 20px;
}

.darul-contact-form h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.darul-contact-form .submit-button {
    background-color: #A5CD37;
    border: none;
    border-radius: 50px;
}