/* General Styles */
.schedule-events {
    background-color: #f9f9f9;
    padding: 30px 0;
}

.schedule-events .heading-content {
    text-align: center;
    margin-bottom: 40px;
}

.schedule-events .head-h1 {
    color: #444;
    font-weight: bold;
    font-size: 2.2em;
    /* Increase font size for larger screens */
}

.schedule-events .para-main {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Event Categories */
.schedule-events .events-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.schedule-events .event-category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.schedule-events .event-items {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 75%;
}

.schedule-events .event-item {
    background-color: transparent;
    border: 1px solid #717C93;
    color: #717C93;
    padding: 10px 20px;
    text-align: center;
    min-width: 150px;
    flex: 1 1 150px;
    border-radius: 26px;
    font-size: 14px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.schedule-events .connect-button {
    width: 190px;
    text-align: center;
}

.schedule-events .divider {
    border: none;
    border-top: 2px solid #030303;
    margin: 15px 0;
    width: 80px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .schedule-events {
        padding: 20px 10px;
    }

    .schedule-events .events-list {
        gap: 20px;
    }

    .schedule-events .event-category {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .schedule-events .event-items {
        width: 100%;
    }

    .schedule-events .event-item {
        flex: 1 1 100%;
        margin-bottom: 10px;
    }

    .schedule-events .connect-button {
        margin-bottom: 10px;
    }

    .schedule-events .para-main {
        font-size: 14px;
        margin-top: 5px;
    }

    .schedule-events .head-h1 {
        font-size: 1.8em;
    }
}

/* Larger screens */
@media (min-width: 1024px) {
    .schedule-events {
        padding: 30px 0;
    }

    .schedule-events .head-h1 {
        /* font-size: 2.5em; */
    }

    .schedule-events .para-main {
        font-size: 18px;
    }

    .schedule-events .event-items {
        gap: 15px;
    }

    .schedule-events .event-item {
        padding: 9px 25px;
        font-size: 13px;
        line-height: 1;
    }
}