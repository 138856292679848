.AdminHeader-container {
    border-bottom: 2px solid #F2F2F2;
    padding: 10px 20px;
}

.AdminHeader-container .menu-btn-container {
    cursor: pointer;
}

.AdminHeader-container .menu-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.AdminHeader-container .h3_main {
    font-size: 18px;
    margin: 0;
    font-weight: 400;
}

.AdminHeader-container .bar {
    display: none;
}

.AdminHeader-container .title {
    color: black;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    margin-left: 10px;
}

.AdminHeader-container .profile-section {
    margin-left: auto;
}

.AdminHeader-container .profile-section .menu-icon {
    width: 40px;
    height: 40px;
}

.AdminHeader-container .navigation-dropdown {
    position: relative;
}

.AdminHeader-container .dropdown-trigger {
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
}

.AdminHeader-container .dropdown-trigger img {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.AdminHeader-container .dropdown-content {
    position: absolute;
    top: 130%;
    left: -70px;
    background-color: white;
    min-width: 150px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 10px;
    display: none;
}

.AdminHeader-container .dropdown-content.open {
    display: block;
}

.AdminHeader-container .dropdown-content .nav-links {
    color: black;
    list-style: none;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 0;
    text-align: center;
}

.AdminHeader-container .dropdown-content .nav-links:hover {
    color: #ee202d;
}

.AdminHeader-container .rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.AdminHeader-container .rotate-back {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.AdminHeader-container .connect-button {
    background: transparent;
    border: none;
    color: #000;
    font-size: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: 991.98px) {
    .AdminHeader-container {
        padding: 0vh 1vh;
    }

    .AdminHeader-container .menu-btn-container {
        margin-right: 10px;
    }

    .AdminHeader-container .title {
        font-size: 16px;
    }

    .AdminHeader-container .profile-section {
        gap: 10px;
    }

    .AdminHeader-container .dropdown-content {
        min-width: 120px;
        padding: 8px;
    }

    .AdminHeader-container .menu-icon {
        width: 20px;
        height: 20px;
    }

    .AdminHeader-container .title {
        color: black;
        font-weight: bold;
        font-size: 18px;
        text-transform: capitalize;
        margin-left: 0px;
    }

    .AdminHeader-container .connect-button {
        font-size: 11px;
    }
}