.PlainCenteredText-container
{
    background-color: #1972b7;
}


.PlainCenteredText-container .main-heading
{
    color: white;

}

.PlainCenteredText-container .para_main
{
    color:white;
}

.PlainCenteredText-container .connect-button
{
    background-color: white;
    color: #A5CD37;
    transition: .3s;
}
.PlainCenteredText-container .connect-button:hover
{
    background-color: #A5CD37;
    color: white;
}